<template>
    <el-dialog append-to-body :visible="visible"
               :width="width"
               :show-close="false"
               :before-close="handleClose">
        <div class="p-tip">
            <div class="p-tip-title">{{title}}</div>
            <div class="p-tip-content">{{content}}</div>
            <div v-if="footer" slot="footer" class="p-tip-footer">
                <el-button v-if="cancelShow" type="default" @click="handleCancel">取消</el-button>
                <el-button type="primary" @click="handleConfirm">{{button}}</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        name: "PTip",
        props: {
            "visible": {
                type: Boolean,
                default: false
            },
            "footer": {
                type: Boolean,
                default: true
            },
            "cancelShow": {
                type: Boolean,
                default: false
            },
            "title": {
                type: String,
                default: "提示"
            },
            "width": {
                type: String,
                default: "340px"
            },
            "button": {
                type: String,
                default: "确定"
            },
            "content": {
                type: String,
                default: ""
            }
        },
        methods: {
            // 关闭回调
            handleClose() {
                this.$emit("close");
                this.$emit("update:visible", false);
            },
            // 点击确认
            handleConfirm() {
                this.$emit("confirm");
                this.handleClose();
            },
            // 点击取消
            handleCancel() {
                this.$emit("cancel");
                this.handleClose();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .p-tip-title {
        font-size: 18px;
        font-weight: 500;
    }

    .p-tip-content {
        padding: 24px 0;
        font-size: 16px;
    }

    .p-tip-footer {
        text-align: right;
    }
</style>
