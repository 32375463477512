<template>
    <span>
        {{ FmtDeductInvoiceStatus(value) }}
    </span>
</template>

<script>
import {FmtEnum} from "../../util/enumUtil";

export default {
  name: "DeductInvoiceStatus",
  props: {
    value: {
      type: String,
      default: null
    }
  },
  methods: {
    FmtDeductInvoiceStatus() {
      const aos = this.$store.state.enums.DeductInvoiceStatus;
      return FmtEnum(aos, this.value);
    }
  }
}
</script>

<style scoped>

</style>