<template>
    <span>
        {{ FmtDeductInvoiceManageStatus(value) }}
    </span>
</template>

<script>
import {FmtEnum} from "../../util/enumUtil";

export default {
  name: "DeductInvoiceManageStatus",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    FmtDeductInvoiceManageStatus() {
      const aos = this.$store.state.enums.DeductInvoiceManageStatus;
      return FmtEnum(aos, this.value);
    }
  }
}
</script>

<style scoped>

</style>